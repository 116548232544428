.gpt3__blog {
    display: flex;
    flex-direction: column;
}

.gpt3__blog-heading {
    width: 100%;
    text-align: left;
    margin-bottom: 5rem;
}

.gpt3__blog-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.gpt3__blog-container {
    display: flex;
    flex-direction: row;
}

.gpt3__blog-conainer-groupA {
    flex: 0.75;
    margin-right: 2rem;
}

.gpt3__blog-conainer-groupB {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}

@media screen and (max-width:990px) {
    .gpt3__blog-container {
        flex-direction: column-reverse;
        gap: 2rem;
    }

    .gpt3__blog-conainer-groupA .gpt3__blog-container_article {
        width: 48%;
    }

    .gpt3__blog-conainer-groupA .gpt3__blog-container_article-image {
        height: 250px;
    }
}

@media screen and (max-width:700px) {
    .gpt3__blog-conainer-groupB {
       grid-template-columns: repeat(1, 1fr); 
    }

    .gpt3__blog-heading h1 {
        font-size: 45px;
        line-height: 52px;
    }

    .gpt3__blog-conainer-groupA .gpt3__blog-container_article {
        width: 100%;
    }
}

@media screen and (max-width:500px) {
    .gpt3__blog-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    
}
.gpt3__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.gpt3__brand div {
    flex: 1;
    max-width: 150px;min-width: 120;
    margin: 1 rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 500) {
    .gpt3__brand>div {
        gap: 1rem;
    }
    .gpt3__brand>div>img{
        width: 60%;
        height: 60%;
    }
}